import Icon from './Icon'

const RightArrowIcon = (props) => (
  <Icon iconName="icon-arrow-right" size={16} {...props}>
    <path d="M10.146 12.854a.5.5 0 0 0 .707 0l5-5 .058-.07a.5.5 0 0 0-.057-.638l-5-5-.07-.057a.5.5 0 0 0-.638.057l-.057.07a.5.5 0 0 0 .057.638L14.293 7H.5l-.09.008A.5.5 0 0 0 .5 8h13.793l-4.147 4.146-.057.07a.5.5 0 0 0 .057.638Z"></path>
  </Icon>
)

const LeftArrowIcon = (props) => (
  <Icon iconName="icon-arrow-left" size={16} {...props}>
    <path d="M5.854 2.146a.5.5 0 0 0-.708 0l-5 5-.057.07a.5.5 0 0 0 .057.638l5 5 .07.057a.5.5 0 0 0 .638-.057l.057-.07a.5.5 0 0 0-.057-.638L1.707 8H15.5l.09-.008A.5.5 0 0 0 15.5 7H1.707l4.147-4.146.057-.07a.5.5 0 0 0-.057-.638Z"></path>
  </Icon>
)

const BotArrowIcon = (props) => (
  <Icon iconName="icon-arrow-down" size={16} {...props}>
    <path d="M8.1 15.795a.495.495 0 0 0 .8 0l4.954-5.263a.555.555 0 0 0 0-.751.478.478 0 0 0-.638-.062l-.07.062L9 14.186V.512A.506.506 0 0 0 8.5 0a.504.504 0 0 0-.492.42L8 .512v13.674L3.854 9.781a.478.478 0 0 0-.638-.062l-.07.062a.557.557 0 0 0-.057.677l.057.074L8.1 15.795Z"></path>
  </Icon>
)

const ArrowRightRound = (props) => (
  <Icon iconName="icon-arrow-right-round" size={16} {...props}>
    <path d="M6.784 4.089a.5.5 0 0 0-.638.057l-.057.07a.5.5 0 0 0 .057.638L9.293 8l-3.147 3.146-.057.07a.5.5 0 0 0 .765.638l3.5-3.5.057-.07a.5.5 0 0 0-.057-.638l-3.5-3.5-.07-.057Z"></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm0 1a7 7 0 1 1 0 14A7 7 0 0 1 8 1Z"
    ></path>
  </Icon>
)

export { RightArrowIcon, LeftArrowIcon, BotArrowIcon, ArrowRightRound }

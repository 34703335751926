import Icon from './Icon'

const InfoIcon = (props) => (
  <Icon iconName="icon-info" size={16} {...props}>
    <path d="M8.747 4.267a1.067 1.067 0 1 1-2.134 0 1.067 1.067 0 0 1 2.134 0ZM8 6.613c.262 0 .48.189.525.438l.009.096v4.906h1.493a.533.533 0 0 1 .096 1.058l-.096.009H5.974a.533.533 0 0 1-.096-1.058l.096-.009h1.493V7.68H5.974a.534.534 0 0 1-.525-.437l-.009-.096c0-.262.19-.48.438-.525l.096-.009H8Z"></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Zm14.933 0A6.933 6.933 0 1 0 1.066 8a6.933 6.933 0 0 0 13.867 0Z"
    ></path>
  </Icon>
)

export default InfoIcon

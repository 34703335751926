import Icon from './Icon'

const CalculatorIcon = ({ size, ...props }) => {
  if (+size > 16) {
    return (
      <Icon iconName="icon-calculator-lg" size={48} {...props}>
        <path d="M10 13a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Zm28.207-1.793a1 1 0 0 0-1.414-1.414L34 12.586l-2.793-2.793a1 1 0 1 0-1.414 1.414L32.586 14l-2.793 2.793a1 1 0 0 0 1.414 1.414L34 15.414l2.793 2.793a1 1 0 0 0 1.414-1.414L35.414 14l2.793-2.793ZM14 29a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3h-3a1 1 0 1 1 0-2h3v-3a1 1 0 0 1 1-1Zm15 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Zm1-7a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Z"></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3a1 1 0 0 0-1 1v40a1 1 0 0 0 1 1h40a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4Zm19 2H5v18h18V5Zm20 0v18H25V5h18ZM23 25H5v18h18V25Zm2 18V25h18v18H25Z"
        ></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-calculator" size={16} {...props}>
      <path d="M3.5 0a.5.5 0 0 1 .492.41L4 .5V3h2.5a.5.5 0 0 1 .09.992L6.5 4H4v2.5a.5.5 0 0 1-.992.09L3 6.5V4H.5a.5.5 0 0 1-.09-.992L.5 3H3V.5a.5.5 0 0 1 .5-.5ZM16 3.5a.5.5 0 0 0-.5-.5h-6l-.09.008A.5.5 0 0 0 9.5 4h6l.09-.008A.5.5 0 0 0 16 3.5Zm-.5 6.5a.5.5 0 0 1 .09.992L15.5 11h-6a.5.5 0 0 1-.09-.992L9.5 10h6Zm.5 3.5a.5.5 0 0 0-.5-.5h-6l-.09.008A.5.5 0 0 0 9.5 14h6l.09-.008A.5.5 0 0 0 16 13.5ZM.646 9.146a.5.5 0 0 1 .638-.057l.07.057L3.5 11.293l2.146-2.147a.5.5 0 0 1 .765.638l-.057.07L4.207 12l2.147 2.146a.5.5 0 0 1-.638.765l-.07-.057L3.5 12.707l-2.146 2.147a.5.5 0 0 1-.765-.638l.057-.07L2.793 12 .646 9.854a.5.5 0 0 1 0-.708Z"></path>
    </Icon>
  )
}

export default CalculatorIcon

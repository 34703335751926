import Icon from './Icon'

const FiltersIcon = ({ size, ...props }) => {
  if (+size > 16) {
    return (
      <Icon iconName="icon-filters-lg" size={size} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.287 24a1 1 0 0 1 1-1H46a1 1 0 1 1 0 2H19.287a1 1 0 0 1-1-1ZM1 24a1 1 0 0 1 1-1h7.857a1 1 0 0 1 0 2H2a1 1 0 0 1-1-1Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.858 16.714a1 1 0 0 1 1 1v12.572a1 1 0 1 1-2 0V17.714a1 1 0 0 1 1-1ZM1 8.286a1 1 0 0 1 1-1h26.714a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Zm36.142 0a1 1 0 0 1 1-1H46a1 1 0 0 1 0 2h-7.857a1 1 0 0 1-1-1Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.142 1a1 1 0 0 1 1 1v12.571a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1ZM1 39.714a1 1 0 0 1 1-1h26.714a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Zm36.142 0a1 1 0 0 1 1-1H46a1 1 0 0 1 0 2h-7.857a1 1 0 0 1-1-1Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.142 32.429a1 1 0 0 1 1 1V46a1 1 0 1 1-2 0V33.429a1 1 0 0 1 1-1Z"
        ></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-filters" size={size} {...props}>
      <path d="M3.8 0a.5.5 0 0 1 .492.41L4.3.5v6.7a.5.5 0 0 1-.992.09L3.3 7.2V4.3H.5a.5.5 0 0 1-.09-.992L.5 3.3h2.8V.5a.5.5 0 0 1 .5-.5ZM16 3.8a.5.5 0 0 0-.5-.5H8l-.09.008A.5.5 0 0 0 8 4.3h7.5l.09-.008A.5.5 0 0 0 16 3.8Zm-7.5 8.4a.5.5 0 0 0-.5-.5H.5l-.09.008a.5.5 0 0 0 .09.992H8l.09-.008a.5.5 0 0 0 .41-.492Zm7-.5a.5.5 0 0 1 .09.992l-.09.008h-2.8v2.8a.5.5 0 0 1-.992.09l-.008-.09V8.8a.5.5 0 0 1 .992-.09l.008.09v2.9h2.8Z"></path>
    </Icon>
  )
}

export default FiltersIcon

import Icon from './Icon'

const TheaterIcon = ({ size, ...props }) => {
  if (+size > 16) {
    return (
      <Icon iconName="icon-theater-lg" size={size} {...props}>
        <path d="M24.347 15.47c-2.385 0-4.36 1.976-4.36 4.36a1.008 1.008 0 1 0 2.016 0c0-1.271 1.072-2.344 2.344-2.344 1.272 0 2.344 1.073 2.344 2.345a1.008 1.008 0 0 0 2.016 0c0-2.385-1.975-4.36-4.36-4.36Zm13.408 0c-2.385 0-4.36 1.976-4.36 4.36a1.008 1.008 0 1 0 2.015 0c0-1.271 1.073-2.344 2.345-2.344 1.271 0 2.344 1.073 2.344 2.345a1.008 1.008 0 0 0 2.016 0c0-2.385-1.975-4.36-4.36-4.36Z"></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.245 26.44a1.01 1.01 0 0 0-1.008 1.009 7.705 7.705 0 0 0 7.713 7.712c4.213 0 7.711-3.499 7.711-7.712 0-.557-.45-1.008-1.007-1.008H24.245Zm6.492 6.701a5.691 5.691 0 0 1-5.395-4.684h11.212c-.488 2.635-2.846 4.686-5.6 4.688l-.217-.004Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.052 4.5c-1.46 0-3.22.182-5.209.51-1.275.21-2.619.475-4 .784-1.007.225-1.99.462-2.926.702h-.003l-.98.257-1.175.325-.007.002c-.378.11-.658.43-.715.82l-.058.449-.001.005c-.043.357-.085.769-.124 1.23-.413 4.836-.2 10.207 1.108 15.299 2.039 7.936 6.437 13.7 13.774 16.119.206.067.427.067.633 0 7.296-2.42 11.695-8.182 13.77-16.115 1.344-5.139 1.582-10.553 1.192-15.4l-.054-.61-.056-.54v-.004a17.48 17.48 0 0 0-.052-.425 1.008 1.008 0 0 0-.72-.83l-.91-.25h-.002c-.182-.05-.373-.1-.575-.153-1.15-.3-2.384-.6-3.652-.881a74.828 74.828 0 0 0-3.963-.773c-2.029-.335-3.819-.521-5.296-.521Zm12.021 20.303c-1.936 7.026-5.785 11.965-11.962 14.155h-.002l-.06.021c-6.375-2.222-10.268-7.326-12.136-14.598-1.241-4.83-1.448-9.977-1.052-14.624v-.002l.058-.62.033-.298.23-.064h.001l.554-.148a95.07 95.07 0 0 1 3.543-.864c1.35-.301 2.657-.56 3.89-.763 1.91-.315 3.56-.483 4.88-.483 1.337 0 3.016.172 4.967.494a72.84 72.84 0 0 1 3.856.752c.993.22 1.965.453 2.891.688h.002l.97.251.006.002.505.137c.025.244.05.515.073.81.375 4.657.143 9.848-1.132 14.726v.001l-.114.425v.002Z"
        ></path>
        <path d="M13.905 15.49a1.008 1.008 0 0 0-1.313-.556c-1.011.41-2.151 1-3.394 1.738a52.01 52.01 0 0 0-2.245 1.419 77.34 77.34 0 0 0-4.15 2.975l-.003.001-.513.397-.407.32-.003.003a1.008 1.008 0 0 0-.347 1.03c.02.08.048.185.083.314l.186.638.002.005c.224.744.5 1.57.83 2.458.94 2.537 2.128 5.077 3.585 7.456 4.473 7.297 10.518 11.599 18.308 11.018a1.01 1.01 0 0 0 .588-.246c.935-.818 1.66-1.566 2.276-2.389a1.008 1.008 0 0 0-1.61-1.213l-.204.264a13.44 13.44 0 0 1-1.479 1.544l-.002.002-.055.05-.032.001h-.001c-6.667.35-11.968-3.392-16.07-10.085-1.381-2.254-2.515-4.676-3.415-7.103a40.062 40.062 0 0 1-.79-2.34l-.05-.17-.002-.003-.019-.066.367-.285.004-.003.56-.424a75.419 75.419 0 0 1 3.48-2.471 49.965 49.965 0 0 1 2.158-1.363c1.176-.7 2.226-1.24 3.12-1.603.517-.209.766-.796.557-1.312Z"></path>
        <path d="M14.82 25.45a1.008 1.008 0 1 0-1.871.748c.409 1.023-.072 2.153-1.064 2.55-1.022.409-2.153-.073-2.55-1.064a1.008 1.008 0 1 0-1.871.749c.816 2.041 3.13 3.002 5.17 2.186 2.041-.816 3.002-3.13 2.186-5.17Z"></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-theater" size={size} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 0H.5a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V.5a.5.5 0 0 0-.5-.5ZM11 1v10H1V1h10Z"
      ></path>
      <path d="M15.992 4.41A.5.5 0 0 0 15.5 4h-2l-.09.008A.5.5 0 0 0 13 4.5l.008.09A.5.5 0 0 0 13.5 5H15v10H5v-1.5l-.008-.09A.5.5 0 0 0 4 13.5v2l.008.09A.5.5 0 0 0 4.5 16h11l.09-.008A.5.5 0 0 0 16 15.5v-11l-.008-.09Z"></path>
    </Icon>
  )
}

export default TheaterIcon

import Icon from './Icon'

const CarIcon = (props) => (
  <Icon iconName="icon-car" size={16} {...props}>
    <path d="M3 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm11-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.153 1.898A1.5 1.5 0 0 0 12.78 1H3.221l-.137.006c-.59.054-1.097.45-1.286 1.02L.03 7.326A.499.499 0 0 0 0 7.5v7l.008.09A.5.5 0 0 0 .5 15h4l.09-.008A.5.5 0 0 0 5 14.5V13h6v1.5l.008.09a.5.5 0 0 0 .492.41h4l.09-.008A.5.5 0 0 0 16 14.5V7.516a.498.498 0 0 0-.026-.177l-1.771-5.313-.05-.128ZM14.806 7l-1.552-4.658a.5.5 0 0 0-.384-.334L12.78 2H3.22a.5.5 0 0 0-.438.259l-.037.083L1.194 7h13.612ZM12 13h3v1h-3v-1Zm3-1H1V8h14v4ZM1 13h3v1H1v-1Z"
    ></path>
  </Icon>
)

export default CarIcon

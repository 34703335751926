import { useEffect, useRef, useState } from 'react'
import { CITY_TYPE, REGION_TYPE } from '../constants/search'

// === STRING UTILS ===

export const ucFirst = (s) => {
  if (typeof s !== 'string') return ''
  return s?.charAt(0)?.toUpperCase() + s?.slice(1)
}

export const tranformJobDescription = (s, l) => {
  if (!s) return ''

  return s.substring(0, l) + ' ...'
}

export const tranformJobLocation = (s) => {
  if (!s) return ''

  return s.split('-')[1] + ' (' + s.split('-')[0].replace(' ', '') + ')'
}

export const capitalize = (string) => ucFirst(string?.toLowerCase())

export const wordsCapitalize = (s) => {
  if (!s) return ''

  const wordBreakerChar = [' ', "'", '-', '.'] // on met une majuscule à la suite de ces caractères
  s = s.toLowerCase()
  wordBreakerChar.forEach((breaker) => {
    s = s
      .split(breaker)
      .map((w) => ucFirst(w))
      .join(breaker)
  })
  return s
}

export const formatCitiesPageTitle = (job, regionLabel, departementLabel) =>
  !!job?.label
    ? `${job.label}${!!regionLabel ? ` en ${regionLabel}` : ''}${
        !!departementLabel ? ` en ${departementLabel}` : ''
      }`
    : null

// todo : replace search
export const formatCitiesPageUrl = (job, city, search) => {
  // redirection page région
  if (!job?.key && !!city && city.type === REGION_TYPE) {
    // cas dom-tom unidépartemental
    if (['1', '2', '3', '4', '6'].includes(city.id)) {
      return `/departement/${city.id}-${city.label
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(' ', '-')
        .toLowerCase()}`
    }
    return `/region/${city.id}`
  }

  // redirection page ville - avec métier
  if (!!job && !!city && city.type === CITY_TYPE) {
    return `/ville/${city.id}-${city.cityName}?codeRome=${job.key}`
  }

  // redirection page ville - sans métier
  if (!job && !!city && city.type === CITY_TYPE) {
    return `/ville/${city.id}-${city.cityName}`
  }

  // redirection page recherche de villes
  const urlSearchParams = new URLSearchParams(search)

  urlSearchParams.delete('codeRome')
  urlSearchParams.delete('codeRegion')

  if (!!job?.key) urlSearchParams.set('codeRome', job.key)
  if (!!city?.id) urlSearchParams.set('codeRegion', city.id)

  // aucun parametre
  if (!job && !city) {
    return '#'
  }

  return `/villes?${urlSearchParams.toString()}`
}

// === DATE UTILS ===

export const formatDateShort = (date) => {
  const [day, month] = date.split('/')
  const months = [
    'dèc.',
    'janv.',
    'fevr.',
    'mars',
    'avril',
    'mai',
    'juin',
    'juil.',
    'aout',
    'sept.',
    'oct.',
    'nov.',
    'dèc.',
  ]
  return `${day} ${months[+month]}`
}

export const formatDateLong = (date) => {
  let dateTab = []
  dateTab = date.split('/')
  const months = [
    'décembre',
    'janvier',
    'fevrier',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'aout',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ]
  return `${dateTab[0]} ${months[+dateTab[1]]} ${dateTab[2].split(' ')[0]}`
}

export const formatDate = (date) => date.toLocaleDateString('fr-FR')

// === FORMS UTILS

export const isDirty = (filters) =>
  Object.values(filters).reduce((prev, currFilter) => {
    if (typeof currFilter === 'string') return prev || currFilter !== ''
    if (Array.isArray(currFilter)) return prev || currFilter?.length > 0
    return prev || !!currFilter
  }, false)

// ===

const numberFormatter = Intl.NumberFormat()
export const formatNumber = (number) =>
  numberFormatter.format(Math.floor(number))

export const useElementOnScreen = (options) => {
  const containerRef = useRef()
  const [isVisible, setIsVisible] = useState(false)

  const callbackFunction = (entries) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (containerRef.current) observer.observe(containerRef.current)

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current)
    }
  }, [containerRef, options])

  return [containerRef, isVisible]
}

export const formatCityTension = (tension) => {
  if (+tension < 4) {
    return "Opportunités d'emploi"
  }
  return "Peu d'opportunités d'emploi"
}

// trie selon le boost de visibilité : 5 > 3 > 2 > null > null ...
export const visibilityBoostSorter = (a, b) =>
  !b?.visibility_boost ? -1 : b?.visibility_boost - a?.visibility_boost

// === URL UTILS ===

const isHttpMatched = (str) =>
  !!str?.match(new RegExp('^(http|https)://'))?.length

export const isExternalLink = (path) =>
  (!!path &&
    typeof path == 'object' &&
    path.hasOwnProperty('pathname') &&
    isHttpMatched(path.pathname)) ||
  (typeof path == 'string' && isHttpMatched(path))

export const formatCityUrl = (city, codeRome) => {
  let url = `/ville/${city.insee_com}-${city.nom_comm}`
  if (!!codeRome) {
    url += `?codeRome=${codeRome}`
  }

  return url
}

export const formatDepartementUrl = (departement) => {
  return `/departement/${departement.code}`
}

export const isOutremer = (codeDepartement) =>
  ['1', '2', '3', '4', '5', '6'].includes(codeDepartement)

export const isPdf = (path) => {
  return path?.endsWith('.pdf')
}

export const alphabetOrder = (key) => (a, b) => a[key].localeCompare(b[key])

export const formatHelpUrl = (help) => `/aide/${help.slug}`

// ======

export function distance(lat1, lon1, lat2, lon2, unit = 'K') {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  } else {
    var radlat1 = (Math.PI * lat1) / 180
    var radlat2 = (Math.PI * lat2) / 180
    var theta = lon1 - lon2
    var radtheta = (Math.PI * theta) / 180
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    if (unit === 'K') {
      dist = dist * 1.609344
    }
    if (unit === 'N') {
      dist = dist * 0.8684
    }
    return dist
  }
}

export const splitSort = (array, numberOfSplits = 2) => {
  const splitLength = Math.ceil(array.length / numberOfSplits)

  let result = []

  let splits = []
  for (let i = 0; i < numberOfSplits; i++) {
    splits[i] = array.slice(i * splitLength, i * splitLength + splitLength)
  }

  for (let i = 0; i <= splitLength; i++) {
    splits.forEach((split) => result.push(split[i]))
  }

  return result.filter((v) => !!v)
}

export const chucksSort = (array, numberOfSplits = 2) => {
  const perChunk = Math.floor(array.length / numberOfSplits)

  return array.reduce((all, one, i) => {
    const ch = Math.floor(i / perChunk)
    all[ch] = [].concat(all[ch] || [], one)
    return all
  }, [])
}

export const filterHelpItemWho = (who) => {
  let whoItems = ''
  const lowerCaseWho = who.toLowerCase()
  if (lowerCaseWho.includes('salarié')) whoItems += 'salarié^'
  if (lowerCaseWho.includes('*tout public')) whoItems += '*tout public^'
  if (lowerCaseWho.includes('alternance')) whoItems += 'alternance^'

  if (lowerCaseWho.includes("demandeur d'emploi avec promesse d'embauche^")) {
    whoItems += "demandeur d'emploi avec promesse d'embauche^"
  } else {
    if (lowerCaseWho.includes("demandeur d'emploi"))
      whoItems += "demandeur d'emploi^"
  }

  if (lowerCaseWho.includes('DE -26 ans')) whoItems += 'DE -26 ans^'
  if (lowerCaseWho.includes('moins de 26 ans')) whoItems += '-26 ans^'
  if (lowerCaseWho.includes('plus de 26 ans')) whoItems += '+26 ans^'
  if (lowerCaseWho.includes('moins de 30 ans')) whoItems += '-30 ans^'
  if (lowerCaseWho.includes('plus de 30 ans')) whoItems += '+30 ans^'
  if (lowerCaseWho.includes('jeune de 18 à 30 ans'))
    whoItems += 'jeune de 18 à 30 ans^'

  if (whoItems.length > 0) whoItems = whoItems.substring(0, whoItems.length - 1)

  return whoItems
}

// === KEY CODE ===
export const ENTER_KEY_CODE = 13
export const ESCAPE_KEY_CODE = 27
export const UP_KEY_CODE = 38
export const DOWN_KEY_CODE = 40

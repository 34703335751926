import React, { Suspense, useEffect, useRef, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { HelmetProvider } from 'react-helmet-async'
import TC_Wrapper from 'react-tag-commander'
import { CircularProgress, createTheme } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import { MobivilleRoutes } from './routes/Routes'
import { SuspenseRouter } from './utils/SuspenseRouter'
import { COLOR_PRIMARY, COLOR_SECONDARY } from './constants/colors'
import Providers from './common/contexts'

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main: COLOR_SECONDARY,
    },
  },
})

const Spinner = (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100dvw',
      height: '100dvh',
    }}
  >
    <svg
      className="spinner spinner-lg"
      viewBox="25 25 50 50"
      aria-hidden="true"
      focusable="false"
    >
      <circle
        className="spinner-path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="4"
        strokeMiterlimit="10"
      ></circle>
    </svg>

    <p role="alert" className="sr-only">
      Chargement de la page en cours
    </p>
  </div>
)

const App = () => {
  const [titleSr, setTitleSr] = useState(
    "Trouvez l'emploi et la ville qui va avec ! | Mobiville"
  )
  const titleSrRef = useRef(null)

  useEffect(() => {
    const wrapper = TC_Wrapper.getInstance()
    // wrapper.setDebug(true)

    const url =
      window.location.host === 'pole-emploi.fr' ||
      window.location.host === 'francetravail.fr'
        ? 'https://cdn.tagcommander.com/4340/tc_PoleEmploi_24.js'
        : 'https://cdn.tagcommander.com/4340/uat/tc_PoleEmploi_24.js'

    wrapper.addContainer('tc', url, 'body').then(() => {
      wrapper.setTcVars({
        customVars: {
          technologie: 'REACT',
          domaine: 'Candidat',
          nom_du_pn: 'px059',
        },
      })
    })
    return () => {
      wrapper.removeContainer('tc')
    }
  }, [])

  useEffect(() => {
    const title = document.querySelector('title')

    const titleObserver = new MutationObserver(function (mutations) {
      setTitleSr(mutations[0].target.innerText)
    })
    titleObserver.observe(title, {
      subtree: true,
      characterData: true,
      childList: true,
    })
  }, [])

  useEffect(() => {
    if (!titleSrRef?.current) return

    titleSrRef?.current.focus()
  }, [titleSrRef?.current])

  return (
    <>
      <p className="sr-only" tabIndex="-1" ref={titleSrRef}>
        {titleSr}
      </p>
      <CssBaseline />
      <SuspenseRouter basename="/mobiville">
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <Providers>
              <Suspense fallback={Spinner}>
                <MobivilleRoutes />
              </Suspense>
            </Providers>
          </HelmetProvider>
        </ThemeProvider>
      </SuspenseRouter>
    </>
  )
}

export default App

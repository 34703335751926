import Icon from './Icon'

const CrossRoadIcon = (props) => (
  <Icon iconName="icon-crossroad" size={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 .5a.5.5 0 0 0-1 0V4H3a.5.5 0 0 0-.354.146l-1.5 1.5a.5.5 0 0 0 0 .708l1.5 1.5A.5.5 0 0 0 3 8h4v7H5.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1H8v-4h4a.5.5 0 0 0 .354-.146l1.5-1.5a.5.5 0 0 0 0-.708l-1.5-1.5A.5.5 0 0 0 12 7H8V5h4a.5.5 0 0 0 .354-.146l1.5-1.5a.5.5 0 0 0 0-.708l-1.5-1.5A.5.5 0 0 0 12 1H8V.5ZM8 2v2h3.793l1-1-1-1H8ZM7 5H3.207l-1 1 1 1H7V5Zm1 3v2h3.793l1-1-1-1H8Z"
    ></path>
  </Icon>
)

export default CrossRoadIcon

const Icon = ({
  children,
  iconName,
  className,
  size = 16,
  ariaHidden = true,
  focusable = false,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    className={`icon ${iconName} ${className ?? ''}`}
    aria-hidden={ariaHidden}
    focusable={focusable}
    fill="currentColor"
    {...props}
  >
    {children}
  </svg>
)

export default Icon

import React from 'react'
import { createRoot } from 'react-dom/client'
import 'bootstrap/dist/js/bootstrap.min.js'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './assets/styles/custom.css'
import './assets/styles/selectize.css'
import './assets/js/pe_selectize.js'

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { createContext, useContext, useEffect, useState } from 'react'
import { useCities } from './citiesContext'
import { useLocation } from 'react-router-dom'
import { useRegions } from './regionsContext'

const searchParamsContext = createContext({
  job: null,
  regionLabel: '',
  departementLabel: '',
  codeCity: '',
  codeEnvironment: '',
  opportunity: '',
  selectedOffer: ''
})

export const SearchParamsProvider = ({ children, ...props }) => {
  const { search } = useLocation()
  const { criterions } = useCities()
  const { regions } = useRegions()

  const [job, setJob] = useState(null)
  const [regionLabel, setRegionLabel] = useState('')
  const [departementLabel, setDepartementLabel] = useState('')

  const [codeCity, setCodeCity] = useState('')
  const [codeEnvironment, setCodeEnvironment] = useState('')
  const [opportunity, setOpportunity] = useState('')

  const [selectedOffer, setSelectedOffer] = useState('')

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search)

    if (!urlSearchParams.has('codeRome')) setJob('')
    if (!urlSearchParams.has('codeRegion')) setRegionLabel('')
    if (!urlSearchParams.has('codeDepartement')) setDepartementLabel('')
    if (!urlSearchParams.has('codeCity')) setCodeCity('')
    if (!urlSearchParams.has('codeEnvironment')) setCodeEnvironment('')
    if (!urlSearchParams.has('opportunity')) setOpportunity('')
    if (!urlSearchParams.has('selectedOffer')) setSelectedOffer('')

    for (let [paramName, value] of urlSearchParams.entries()) {
      if (paramName === 'codeRome' && !!criterions?.codeRomes) {
        const job = criterions.codeRomes.find(
          (codeRome) => value === codeRome.key
        )
        setJob(job)
      } else if (paramName === 'codeRegion' && !!criterions?.regions) {
        const region = criterions.regions.find((region) => value === region.id)
        setRegionLabel(region?.label)
      } else if (paramName === 'codeDepartement' && !!criterions?.regions) {
        const selectedDepartement = regions
          .map((r) => r.departements)
          .flat()
          .find((d) => d.code === value)
        setDepartementLabel(selectedDepartement?.name)
      } else if (paramName === 'codeCity') {
        setCodeCity(value)
      } else if (paramName === 'codeEnvironment') {
        setCodeEnvironment(value)
      } else if (paramName === 'opportunity') {
        setOpportunity(value)
      } else if (paramName === 'selectedOffer') {
        setSelectedOffer(value)
      }
    }
  }, [ search, criterions, regions ])
  
  return (
    <searchParamsContext.Provider
      value={{
        job,
        regionLabel,
        departementLabel,
        codeCity,
        codeEnvironment,
        opportunity,
        selectedOffer
      }}
      {...props}
    >
      {children}
    </searchParamsContext.Provider>
  )
}

export const useSearchParams = () => {
  const context = useContext(searchParamsContext)
  if (!context)
    throw new Error('useSearchParams must be used in SearchParamsProvider')

  return context
}

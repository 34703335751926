import Icon from './Icon'

const BulbOn = (props) => (
  <Icon iconName="icon-bulb-on" size={16} {...props}>
    <path d="M9 .5a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0v-2ZM3.196 2.49a.5.5 0 0 0-.707.707L3.903 4.61a.5.5 0 0 0 .707-.708L3.196 2.49Zm11.314 0a.5.5 0 0 1 0 .707L13.098 4.61a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0Z"></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.513 3.981A4.5 4.5 0 0 0 6 12.223V15.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-3.277a4.482 4.482 0 0 0 2-3.72V8.5a4.5 4.5 0 0 0-4.487-4.52ZM6.757 5.446A3.5 3.5 0 0 1 12 8.498a3.483 3.483 0 0 1-1.749 3.013.5.5 0 0 0-.251.434V15H7v-3.055a.5.5 0 0 0-.25-.433 3.5 3.5 0 0 1 .007-6.066Z"
    ></path>
    <path d="M14 8.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5ZM1.5 8a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1Z"></path>{' '}
  </Icon>
)

export default BulbOn
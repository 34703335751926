import Icon from './Icon'

const MailIcon = ({ size, ...props }) => {
  if (+size > 16) {
    return (
      <Icon iconName="icon-mail-lg" size={size} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.293 12.293a1 1 0 0 1 1.414 0L24 26.586l14.293-14.293a1 1 0 0 1 1.414 1.414l-15 15a1 1 0 0 1-1.414 0l-15-15a1 1 0 0 1 0-1.414Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 7a3 3 0 0 0-3 3v28a3 3 0 0 0 3 3h36a3 3 0 0 0 3-3V10a3 3 0 0 0-3-3H6Zm-5 3a5 5 0 0 1 5-5h36a5 5 0 0 1 5 5v28a5 5 0 0 1-5 5H6a5 5 0 0 1-5-5V10Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.707 27.293a1 1 0 0 1 0 1.414l-7 7a1 1 0 0 1-1.414-1.414l7-7a1 1 0 0 1 1.414 0Zm14.586 0a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414Z"
        ></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-mail" size={size} {...props}>
      <path d="M13.887 4.183a.5.5 0 0 0-.704-.07L8 8.355 2.817 4.113l-.075-.05a.5.5 0 0 0-.559.824l5.5 4.5.073.05a.5.5 0 0 0 .56-.05l5.5-4.5.065-.063a.5.5 0 0 0 .006-.64Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 1h-13A1.5 1.5 0 0 0 0 2.5v11A1.5 1.5 0 0 0 1.5 15h13a1.5 1.5 0 0 0 1.5-1.5v-11A1.5 1.5 0 0 0 14.5 1Zm-13 1h13a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5Z"
      ></path>
    </Icon>
  )
}

export default MailIcon

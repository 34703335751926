import Icon from './Icon'

const BriefcaseIcon = ({ size, ...props }) => {
  if (+size > 16) {
    return (
      <Icon iconName="icon-briefcase-lg" size={48} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V4H18v5a1 1 0 1 1-2 0V3Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 10a1 1 0 0 0-1 1v19h16a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1V11a3 3 0 0 1 3-3h40a3 3 0 0 1 3 3v20a1 1 0 0 1-1 1H29a1 1 0 1 1 0-2h16V11a1 1 0 0 0-1-1H4Zm0 25a1 1 0 0 1 1 1v7a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1v-7a1 1 0 1 1 2 0v7a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-7a1 1 0 0 1 1-1Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 27a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H19a1 1 0 0 1-1-1v-8Zm2 1v6h8v-6h-8Z"
        ></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-briefcase" size={16} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.992.41A.5.5 0 0 0 10.5 0h-5l-.09.008A.5.5 0 0 0 5 .5V3H1.5l-.144.007A1.5 1.5 0 0 0 0 4.5v5l.008.09A.5.5 0 0 0 .5 10H6v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V10h5.5l.09-.008A.5.5 0 0 0 16 9.5v-5l-.007-.144A1.5 1.5 0 0 0 14.5 3H11V.5l-.008-.09ZM10 9h5V4.5a.5.5 0 0 0-.41-.492L14.5 4h-13a.5.5 0 0 0-.492.41L1 4.5V9h5V7.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V9Zm-3 2h2V8H7v3Zm3-8V1H6v2h4Z"
      ></path>
      <path d="M14.5 11a.5.5 0 0 1 .492.41l.008.09v2a1.5 1.5 0 0 1-1.355 1.493L13.5 15h-11a1.5 1.5 0 0 1-1.493-1.355L1 13.5v-2a.5.5 0 0 1 .992-.09L2 11.5v2a.5.5 0 0 0 .41.492L2.5 14h11a.5.5 0 0 0 .492-.41L14 13.5v-2a.5.5 0 0 1 .5-.5Z"></path>
    </Icon>
  )
}

export default BriefcaseIcon

import Icon from './Icon'

const TrainIcon = ({ size = 16, ...props }) => {
  if (+size > 16) {
    return (
      <Icon iconName="icon-train-lg" size={size} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 7a1 1 0 0 0-.986 1.164l2 12A1 1 0 0 0 15 21h18a1 1 0 0 0 .986-.836l2-12A1 1 0 0 0 35 7H13Zm2.847 12L14.181 9h19.638l-1.666 10H15.847ZM12 27.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Zm3.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm17-2a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM31 27.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 36h.447l-4.358 9.586a1 1 0 1 0 1.821.828L10.916 42h26.168l2.006 4.414a1 1 0 1 0 1.82-.828L36.554 36H37a5 5 0 0 0 5-5V6a5 5 0 0 0-5-5H11a5 5 0 0 0-5 5v25a5 5 0 0 0 5 5Zm0-33a3 3 0 0 0-3 3v25a3 3 0 0 0 3 3h26a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H11Zm.826 37 1.818-4h20.712l1.818 4H11.826Z"
        ></path>
        <path d="M2 11a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Zm45 1a1 1 0 1 0-2 0v6a1 1 0 1 0 2 0v-6Z"></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-train" size={size} {...props}>
      <path d="M6 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 0h9A1.5 1.5 0 0 1 14 1.5v11a.5.5 0 0 1-.5.5h-1.066l1.482 2.223a.5.5 0 0 1-.775.625l-.057-.07L11.232 13H4.768l-1.852 2.777a.5.5 0 0 1-.875-.475l.043-.08L3.566 13H2.5a.5.5 0 0 1-.5-.5v-11A1.5 1.5 0 0 1 3.5 0ZM3 8v4h10V8H3Zm10-1H3V1.5a.5.5 0 0 1 .5-.5h9l.09.008A.5.5 0 0 1 13 1.5V7Z"
      ></path>
      <path d="M.5 3a.5.5 0 0 1 .492.41L1 3.5v3a.5.5 0 0 1-.992.09L0 6.5v-3A.5.5 0 0 1 .5 3Zm15.492.41A.5.5 0 0 0 15 3.5v3l.008.09A.5.5 0 0 0 16 6.5v-3l-.008-.09Z"></path>
    </Icon>
  )
}

export default TrainIcon

import Icon from "./Icon"

const StopIcon = (props) => (
  <Icon iconName="icon-stop" size={16} {...props}>
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5h-13ZM0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13Z"
      ></path>
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h16v16H0z"></path>
      </clipPath>
    </defs>
  </Icon>
)

export default StopIcon
import Icon from './Icon'

const CompassIcon = ({ size, ...props }) => {
  if (size === 'lg') {
    return (
      <Icon iconName="icon-compass-lg" size={48} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 1a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1Zm15 23a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1ZM24 39a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1ZM1 24a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Zm18.293-4.707a1 1 0 0 1 1.414 0l8 8a1 1 0 0 1-1.414 1.414l-8-8a1 1 0 0 1 0-1.414Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 3C12.402 3 3 12.402 3 24s9.402 21 21 21 21-9.402 21-21S35.598 3 24 3ZM1 24C1 11.297 11.297 1 24 1s23 10.297 23 23-10.297 23-23 23S1 36.703 1 24Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.707 13.293a1 1 0 0 1 .212 1.1l-6 14a1 1 0 0 1-.525.526l-14 6a1 1 0 0 1-1.313-1.313l6-14a1 1 0 0 1 .525-.525l14-6a1 1 0 0 1 1.101.212Zm-13.945 7.469-4.858 11.334 11.334-4.858 4.858-11.334-11.334 4.858Z"
        ></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-compass" size={16} {...props}>
      <g clipPath="url(#a)">
        <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1ZM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Z"></path>
        <path d="M11.854 4.146a.498.498 0 0 1 .111.539l-2 5a.502.502 0 0 1-.279.28l-5 2a.501.501 0 0 1-.65-.651l2-5a.5.5 0 0 1 .279-.279l5-2a.502.502 0 0 1 .539.111ZM6.885 6.885l-1.487 3.718 3.718-1.487 1.487-3.718-3.718 1.487Z"></path>
        <path d="M9.67 8.994 7.046 6.307l-.716.7 2.626 2.687.716-.7Z"></path>
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </Icon>
  )
}

export default CompassIcon

import Icon from "./Icon"

const HomeIcon = (props) => (
  <Icon iconName="icon-home" size={16} {...props}>
    <path d="M8.265.076a.5.5 0 0 0-.606.058l-7.5 7-.06.068a.5.5 0 0 0 .035.64l.068.06a.5.5 0 0 0 .64-.036L8 1.185l7.159 6.68.071.056a.5.5 0 0 0 .611-.787l-7.5-7-.076-.058Z"></path>
    <path d="M13.992 8.41A.5.5 0 0 0 13.5 8l-.09.008A.5.5 0 0 0 13 8.5V15h-3v-3.5l-.008-.09A.5.5 0 0 0 9.5 11h-3l-.09.008A.5.5 0 0 0 6 11.5V15H3V8.5l-.008-.09A.5.5 0 0 0 2 8.5v7l.008.09A.5.5 0 0 0 2.5 16h4l.09-.008A.5.5 0 0 0 7 15.5V12h2v3.5l.008.09A.5.5 0 0 0 9.5 16h4l.09-.008A.5.5 0 0 0 14 15.5v-7l-.008-.09Z"></path>
  </Icon>
)

export default HomeIcon

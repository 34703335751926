import Icon from './Icon'

const WalletIcon = (props) => (
  <Icon iconName="icon-wallet" size={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 0a.5.5 0 0 1 .492.41L12 .5v2a.5.5 0 0 1-.992.09L11 2.5V1H2.5a1.5 1.5 0 0 0-1.493 1.356L1 2.5a1.5 1.5 0 0 0 1.356 1.493L2.5 4h13a.5.5 0 0 1 .492.41L16 4.5v11a.5.5 0 0 1-.41.492L15.5 16h-13a2.5 2.5 0 0 1-2.495-2.336L0 13.5v-11l.005-.164a2.5 2.5 0 0 1 2.33-2.33L2.5 0h9ZM1.024 4.518 1 4.499V13.5a1.5 1.5 0 0 0 1.356 1.493L2.5 15H15V5H2.5c-.552 0-1.063-.18-1.476-.482Z"
    ></path>
  </Icon>
)

export default WalletIcon

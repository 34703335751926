import Icon from './Icon'

const PinmapIcon = ({ size, ...props }) => (
  <Icon iconName="icon-pinmap" size={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 1a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0a6 6 0 0 0-6 6c0 .434.047.879.138 1.334.347 1.737 1.317 3.565 2.714 5.406a26.13 26.13 0 0 0 1.927 2.254l.333.341.51.496.042.039a.5.5 0 0 0 .672 0l.248-.234.304-.3a26.122 26.122 0 0 0 2.26-2.596c1.397-1.841 2.367-3.669 2.714-5.406A6.8 6.8 0 0 0 14 6a6 6 0 0 0-6-6Zm0 1a5 5 0 0 1 5 5c0 .366-.04.745-.118 1.137-.313 1.563-1.217 3.267-2.53 4.998a25.135 25.135 0 0 1-1.854 2.168l-.319.327-.179.178-.335-.337a25.126 25.126 0 0 1-2.016-2.336C4.334 10.404 3.43 8.7 3.118 7.137A5.802 5.802 0 0 1 3 6a5 5 0 0 1 5-5Z"
    ></path>
  </Icon>
)

export default PinmapIcon

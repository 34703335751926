import Icon from './Icon'

const SearchUserIcon = ({ size, ...props }) => {
  if (+size > 16) {
    return (
      <Icon iconName="icon-search-user-lg" size={48} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.41 23.97c-4.41-1.3-8.55-1.28-12.83-.01a4.965 4.965 0 0 0-3.58 4.8V31c0 .55.45 1 1 1h18c.55 0 1-.45 1-1v-2.23c0-2.24-1.44-4.17-3.59-4.8ZM14 30v-1.24c0-1.34.86-2.5 2.15-2.88 3.9-1.16 7.65-1.18 11.69.01 1.29.38 2.16 1.54 2.16 2.88V30H14Zm14-15c0 3.24-2.62 7-6 7s-6-3.69-6-7 2.69-6 6-6 6 2.76 6 6Zm-2 0c0-2.21-1.79-4-4-4s-4 1.75-4 4 1.85 5 4 5 4-2.79 4-5Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m46.71 45.29-9.19-9.19C40.91 32.37 43 27.43 43 22c0-11.58-9.42-21-21-21S1 10.42 1 22s9.42 21 21 21c5.43 0 10.37-2.09 14.1-5.48l9.19 9.19c.2.2.45.29.71.29.26 0 .51-.1.71-.29a.996.996 0 0 0 0-1.41v-.01ZM3 22C3 11.52 11.52 3 22 3s19 8.52 19 19-8.52 19-19 19S3 32.48 3 22Z"
        ></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-search-user" size={16} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 2.4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0 1a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 0a6.5 6.5 0 0 0-4.428 11.259.498.498 0 0 0 .22.196A6.474 6.474 0 0 0 6.5 13a6.474 6.474 0 0 0 4.207-1.545.497.497 0 0 0 .221-.197A6.5 6.5 0 0 0 6.5 0Zm4.106 10.16a5.5 5.5 0 1 0-8.185.03 5.848 5.848 0 0 1 1.972-1.745c2.246-1.021 4.914-.256 6.213 1.715Zm-7.443.713A5.476 5.476 0 0 0 6.5 12a5.476 5.476 0 0 0 3.36-1.146C8.872 9.19 6.664 8.511 4.844 9.337a4.788 4.788 0 0 0-1.68 1.536Z"
      ></path>
      <path d="M12.784 12.089a.5.5 0 0 0-.638.765l3 3 .07.057a.5.5 0 0 0 .638-.765l-3-3-.07-.057Z"></path>
    </Icon>
  )
}

export default SearchUserIcon

import React, { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

const Home = lazy(() => import('./home/Home'))

const Cities = lazy(() => import('./cities/Cities'))
const City = lazy(() => import('./city/City'))

const Region = lazy(() => import('./region/Region'))
const Departement = lazy(() => import('./departement/Departement'))

const Helps = lazy(() => import('./helps/Helps'))
const HelpDetailsPage = lazy(() => import('./helps/HelpDetailsPage'))

const GuideHome = lazy(() => import('./mobility-guide/GuideHome'))
const GuideStep1 = lazy(() => import('./mobility-guide/GuideStep1'))
const GuideStep2 = lazy(() => import('./mobility-guide/GuideStep2'))
const GuideStep3 = lazy(() => import('./mobility-guide/GuideStep3'))
const GuideStep4 = lazy(() => import('./mobility-guide/GuideStep4'))
const GuideStep5 = lazy(() => import('./mobility-guide/GuideStep5'))

export const MobivilleRoutes = () => (
  <Routes>
    <Route index path="/" element={<Home />} />

    <Route path="/villes" element={<Cities />} />
    <Route path="/ville/:insee" element={<City />} />
    <Route path="/ville/:insee/:section" element={<City />} />

    <Route path="/region/:codeSlug" element={<Region />} />
    <Route path="/departement/:codeSlug" element={<Departement />} />

    <Route path="/aide" element={<Helps />} />
    <Route path="/aide/:slug" element={<HelpDetailsPage />} />

    <Route path="/conseils-et-astuces" element={<GuideHome />} />
    <Route path="/conseils-et-astuces/etape-1" element={<GuideStep1 />} />
    <Route path="/conseils-et-astuces/etape-2" element={<GuideStep2 />} />
    <Route path="/conseils-et-astuces/etape-3" element={<GuideStep3 />} />
    <Route path="/conseils-et-astuces/etape-4" element={<GuideStep4 />} />
    <Route path="/conseils-et-astuces/etape-5" element={<GuideStep5 />} />

    {/* Redirection sur la home page si le path ne match aucune route */}
    <Route
      path="*"
      element={
        <>
          <Navigate to="/" replace />
        </>
      }
    />
  </Routes>
)

import Icon from './Icon'

const DealIcon = ({ size, ...props }) => {
  if (+size > 16) {
    return (
      <Icon iconName="icon-deal-lg" size={size} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 3a6 6 0 1 0 0 12A6 6 0 0 0 9 3ZM5 9a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
        ></path>
        <path d="M8 18a7 7 0 0 0-7 7v18a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V31a1 1 0 1 0-2 0v11H3V25a5 5 0 0 1 5-5c1.299 0 2.225.34 3.01.882.819.564 1.52 1.371 2.331 2.395l.438.558c1.51 1.934 3.373 4.319 6.765 6.055a1 1 0 0 0 .912-1.78c-2.985-1.528-4.595-3.584-6.11-5.518-.146-.187-.291-.373-.437-.556-.814-1.029-1.675-2.05-2.763-2.8C11.025 18.461 9.701 18 8 18Zm26.221 5.836c-1.51 1.934-3.372 4.318-6.765 6.054a1 1 0 0 1-.911-1.78c2.984-1.528 4.595-3.584 6.11-5.518.145-.187.29-.373.436-.556.814-1.029 1.675-2.05 2.763-2.8C36.975 18.461 38.3 18 40 18a7 7 0 0 1 7 7v18a1 1 0 0 1-1 1H34a1 1 0 0 1-1-1V31a1 1 0 1 1 2 0v11h10V25a5 5 0 0 0-5-5c-1.298 0-2.225.34-3.01.882-.818.564-1.52 1.371-2.33 2.395-.144.181-.29.368-.439.559Z"></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39 3a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm-4 6a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
        ></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-deal" size={size} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z"
      ></path>
      <path d="m4.674 6.96-.021-.027-.155-.143C3.89 6.254 3.29 6 2.5 6A2.445 2.445 0 0 0 0 8.514V14.5l.008.09A.5.5 0 0 0 .5 15h4l.09-.008A.5.5 0 0 0 5 14.5v-4l-.008-.09A.5.5 0 0 0 4.5 10l-.09.008A.5.5 0 0 0 4 10.5v3.499H1V8.5l.003-.153A1.446 1.446 0 0 1 2.485 7c.593 0 .993.19 1.487.664a9.7 9.7 0 0 1 .498.525l.362.406.491.536c.443.466.868.863 1.365 1.26a.5.5 0 0 0 .624-.782c-.777-.62-2.428-2.403-2.638-2.648Zm6.828-.17c.608-.536 1.207-.79 1.984-.79a2.445 2.445 0 0 1 2.511 2.303L16 8.5v6a.5.5 0 0 1-.41.492L15.5 15h-4a.5.5 0 0 1-.492-.41L11 14.5v-4a.5.5 0 0 1 .992-.09l.008.09V14h3V8.486a1.445 1.445 0 0 0-.323-.953l-.1-.11A1.454 1.454 0 0 0 13.5 7c-.579 0-.978.19-1.472.664a7.148 7.148 0 0 0-.274.28l-.809.9a13.79 13.79 0 0 1-1.633 1.547.5.5 0 0 1-.624-.782l.194-.163c.9-.786 2.5-2.532 2.465-2.513l.155-.143Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 1a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z"
      ></path>
    </Icon>
  )
}

export default DealIcon

import Icon from './Icon'

const CommentIcon = (props) => (
  <Icon iconName="icon-comment-lg" size={48} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 22C1 11.372 11.441 3 24 3s23 8.372 23 19c0 4.546-1.9 8.664-5.151 11.968l1.144 9.917a1 1 0 0 1-1.363 1.044l-11.526-4.59C28.155 40.716 26.087 41 24 41 11.441 41 1 32.628 1 22ZM24 5C12.159 5 3 12.828 3 22s9.159 17 21 17c2.009 0 4.038-.287 6.004-.68a1 1 0 0 1 .566.051l10.245 4.08-1.008-8.736a1 1 0 0 1 .297-.833C43.238 29.842 45 26.092 45 22c0-9.172-9.158-17-21-17Z"
    ></path>
  </Icon>
)

const CommentIconDots = (props) => (
  <Icon iconName="" size={76} {...props}>
    <path
      d="M50.2707 39.4682C48.8054 39.2858 47.7654 37.9501 47.9478 36.4848C48.1302 35.0195 49.4659 33.9795 50.9312 34.1619C52.3965 34.3443 53.4365 35.68 53.2541 37.1453C53.0717 38.6106 51.736 39.6506 50.2707 39.4682Z"
      fill="currentColor"
    />
    <path
      d="M38.3316 37.9821C36.8663 37.7997 35.8263 36.4639 36.0087 34.9987C36.1911 33.5334 37.5268 32.4934 38.9921 32.6758C40.4574 32.8582 41.4974 34.1939 41.315 35.6592C41.1326 37.1245 39.7969 38.1644 38.3316 37.9821Z"
      fill="currentColor"
    />
    <path
      d="M24.0696 33.5125C23.8872 34.9778 24.9272 36.3135 26.3925 36.4959C27.8578 36.6783 29.1935 35.6383 29.3759 34.173C29.5583 32.7077 28.5183 31.372 27.053 31.1896C25.5877 31.0072 24.252 32.0472 24.0696 33.5125Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.1065 37.8791C73.285 22.4249 59.0301 9.09321 40.7687 7.70072C22.5073 6.30823 6.39582 17.3244 5.21738 32.7786C4.71333 39.3888 7.01997 45.5879 11.3805 50.752L8.61675 65.0466C8.51931 65.5506 8.69399 66.0687 9.07668 66.4107C9.45936 66.7528 9.9937 66.8685 10.4836 66.7154L27.7527 61.3178C30.5447 62.084 33.521 62.7256 36.5552 62.957C54.8166 64.3495 70.9281 53.3333 72.1065 37.8791ZM40.5469 10.6089C57.7658 11.9219 70.2153 24.3202 69.1983 37.6573C68.1813 50.9945 53.9959 61.3617 36.777 60.0487C33.856 59.826 30.9377 59.1832 28.1221 58.3934C27.8504 58.3172 27.5625 58.3214 27.2932 58.4056L11.9438 63.2032L14.3785 50.6106C14.4645 50.1658 14.3389 49.7062 14.0385 49.367C9.81776 44.5998 7.67188 38.9506 8.1256 33.0004C9.14261 19.6632 23.328 9.29595 40.5469 10.6089Z"
      fill="currentColor"
    />
  </Icon>
)

export { CommentIcon, CommentIconDots }

import Icon from './Icon'

const RightChevronIcon = (props) => (
  <Icon iconName="icon-chevron-r" size={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.146.146A.5.5 0 0 1 5.784.09l.07.057 7.5 7.5a.5.5 0 0 1 .057.638l-.057.07-7.5 7.5a.5.5 0 0 1-.765-.638l.057-.07L12.293 8 5.146.854A.5.5 0 0 1 5.09.216l.057-.07Z"
    ></path>
  </Icon>
)

const DownChevronIcon = (props) => (
  <Icon iconName="icon-chevron-d" size={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.146 4.146a.5.5 0 0 1 .638-.057l.07.057L8 11.293l7.146-7.147a.5.5 0 0 1 .638-.057l.07.057a.5.5 0 0 1 .057.638l-.057.07-7.5 7.5a.5.5 0 0 1-.638.057l-.07-.057-7.5-7.5a.5.5 0 0 1 0-.708Z"
    ></path>
  </Icon>
)

const LeftChevronIcon = (props) => (
  <Icon iconName="icon-chevron-l" size={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.646.146a.5.5 0 0 1 .765.638l-.057.07L4.207 8l7.147 7.146a.5.5 0 0 1 .057.638l-.057.07a.5.5 0 0 1-.638.057l-.07-.057-7.5-7.5a.5.5 0 0 1-.057-.638l.057-.07 7.5-7.5Z"
    />
  </Icon>
)

const DoubleRightChevronIcon = (props) => (
  <Icon iconName="icon-double-chevron-r" size={16} {...props}>
    <path d="M7.173 15.878a.5.5 0 0 0 .705-.05l6.5-7.5.053-.075a.5.5 0 0 0-.053-.58l-6.5-7.5L7.813.11a.5.5 0 0 0-.64.012L7.11.187a.5.5 0 0 0 .012.64L13.34 8l-6.217 7.172-.053.074a.5.5 0 0 0 .104.632Z"></path>
    <path d="M1.173 15.878a.5.5 0 0 0 .705-.05l6.5-7.5.053-.075a.5.5 0 0 0-.053-.58l-6.5-7.5L1.813.11a.5.5 0 0 0-.64.012L1.11.187a.5.5 0 0 0 .012.64L7.34 8l-6.217 7.172-.053.074a.5.5 0 0 0 .104.632Z"></path>
  </Icon>
)

const DoubleLeftChevronIcon = (props) => (
  <Icon iconName="icon-double-chevron-l" size={16} {...props}>
    <path d="M8.327.122a.5.5 0 0 0-.705.05l-6.5 7.5-.053.075a.5.5 0 0 0 .053.58l6.5 7.5.065.063a.5.5 0 0 0 .64-.012l.063-.065a.5.5 0 0 0-.012-.64L2.16 8 8.378.827 8.43.754a.5.5 0 0 0-.104-.632Z"></path>
    <path d="M14.328.122a.5.5 0 0 0-.706.05l-6.5 7.5-.053.075a.5.5 0 0 0 .053.58l6.5 7.5.065.063a.5.5 0 0 0 .64-.012l.063-.065a.5.5 0 0 0-.012-.64L8.16 8 14.378.827l.053-.073a.5.5 0 0 0-.103-.632Z"></path>
  </Icon>
)

export {
  RightChevronIcon,
  DownChevronIcon,
  LeftChevronIcon,
  DoubleRightChevronIcon,
  DoubleLeftChevronIcon,
}

import Icon from './Icon'

const CalendarIcon = ({ size, ...props }) => (
  <Icon iconName="icon-calendar" size={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 0a.5.5 0 0 1 .492.41L5 .5V2h6V.5a.5.5 0 0 1 .992-.09L12 .5V2h2.5A1.5 1.5 0 0 1 16 3.5v11a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-11A1.5 1.5 0 0 1 1.5 2H4V.5a.5.5 0 0 1 .5-.5Zm-3 3a.5.5 0 0 0-.5.5V5h14V3.5a.5.5 0 0 0-.5-.5h-13ZM1 6h14v8.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V6Z"
    ></path>
  </Icon>
)

export default CalendarIcon

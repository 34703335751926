import Icon from './Icon'

const HandoutIcon = ({ size, ...props }) => {
  if (size === 'lg') {
    return (
      <Icon iconName="icon-handout-lg" size={48} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 9a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm6-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm14 8a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm-4 6a4 4 0 1 1 8 0 4 4 0 0 1-8 0ZM9 25v-1a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h6a1 1 0 0 0 .888-.54l9.535 4.027a.985.985 0 0 0 .088.033 10.36 10.36 0 0 0 5.517.199c.038-.01.075-.02.112-.034l21.203-7.746a1 1 0 0 0 .617-1.22L45 36l.96-.281v-.001l-.002-.003-.002-.006-.005-.018-.006-.02-.01-.031a6.168 6.168 0 0 0-.296-.707 7.16 7.16 0 0 0-1.082-1.6C43.495 32.147 41.734 31 39 31h-9.413c-1.243-1.16-2.892-2-4.587-2h-5.485c-1.95-2.573-5.134-4-8.515-4H9Zm-2 0H3v14h4V25Zm2 13.337V27h2c2.997 0 5.68 1.332 7.17 3.556A1 1 0 0 0 19 31h6c1.114 0 2.374.608 3.383 1.617l.033.033c.026.031.055.06.085.088.654.688 1.124 1.482 1.35 2.262H17a1 1 0 1 0 0 2h14a1 1 0 0 0 1-1c0-1.046-.32-2.074-.837-3H39c2.099 0 3.338.853 4.067 1.667.237.264.423.53.568.767L23.51 42.786a8.358 8.358 0 0 1-4.354-.16L9 38.336Z"
        ></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-handout" size={16} {...props}>
      <path d="M14.5 3.544a.503.503 0 0 0-.5-.507h-3l-.09.009a.505.505 0 0 0-.41.498c0 .28.224.506.5.506h3l.09-.008a.505.505 0 0 0 .41-.498Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.544C9 1.587 10.567 0 12.5 0S16 1.587 16 3.544s-1.567 3.543-3.5 3.543S9 5.501 9 3.544Zm6 0c0-1.398-1.12-2.532-2.5-2.532S10 2.146 10 3.544c0 1.397 1.12 2.53 2.5 2.53S15 4.942 15 3.545Z"
      ></path>
      <path d="m.5 6.075 3.68.001c.133.002.233.006.348.012.177.01.345.027.509.051l.242.043 4.42.712c.73.149 1.301.86 1.301 1.61 0 .838-.646 1.539-1.455 1.614l-.145.006H6l2.59 1.968 3.652-1.233c1.352-.456 2.84.15 3.602 1.39l.095.164a.51.51 0 0 1-.197.686l-.084.037-8.072 2.723c-.588.238-1.36.167-1.996-.158l-.17-.094-5.2-3.544a.51.51 0 0 1-.135-.703.497.497 0 0 1 .615-.18l.08.043 5.177 3.53c.343.208.805.285 1.158.197l.127-.041 7.45-2.515-.04-.044c-.519-.51-1.25-.743-1.936-.576l-.158.046-3.9 1.316a.495.495 0 0 1-.375-.024L8.2 13.06l-4-3.038a.509.509 0 0 1 .213-.904l.087-.007h4.9c.324 0 .6-.28.6-.607 0-.24-.187-.5-.398-.589l-.08-.025-4.42-.712a4.178 4.178 0 0 0-.415-.063l-.215-.017a7.69 7.69 0 0 0-.415-.011H.5A.503.503 0 0 1 0 6.58c0-.28.224-.506.5-.506Z"></path>
    </Icon>
  )
}

export default HandoutIcon

import Icon from './Icon'

const ClockIcon = (props) => (
  <Icon iconName="icon-clock" size={16} {...props}>
    <path d="M8.525 3.637a.534.534 0 0 0-1.058.096V8l.008.096A.534.534 0 0 0 8 8.533h4.267l.096-.008A.533.533 0 0 0 12.8 8l-.009-.096a.533.533 0 0 0-.524-.437H8.533V3.733l-.008-.096Z"></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm0 1.067a6.933 6.933 0 1 1 0 13.866A6.933 6.933 0 0 1 8 1.067Z"
    ></path>
  </Icon>
)

export default ClockIcon

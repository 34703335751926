import Icon from './Icon'

const BuildingIcon = ({ size = 16, ...props }) => {
  if (+size > 16) {
    return (
      <Icon iconName="icon-building-lg" size={size} {...props}>
        <path d="M16 31a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1 5a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Zm-1-11a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z"></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.263 2.035A1 1 0 0 0 22 3v9a1 1 0 1 0 2 0V4.31l20 5.454V44H28V17a1 1 0 0 0-1-1H11a1 1 0 0 0-1 1v27H4V26h2a1 1 0 1 0 0-2H3a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1V9a1 1 0 0 0-.737-.965l-22-6ZM26 18v26H12V18h14Z"
        ></path>
        <path d="M33 14a1 1 0 0 1 1 1v22a1 1 0 1 1-2 0V15a1 1 0 0 1 1-1Zm7 3a1 1 0 1 0-2 0v20a1 1 0 1 0 2 0V17Z"></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-building" size={size} {...props}>
      <path d="M7.5 9.5a.5.5 0 0 1 .09.992l-.09.008h-2a.5.5 0 0 1-.09-.991L5.5 9.5h2ZM8 12a.5.5 0 0 0-.5-.5h-2l-.09.008a.5.5 0 0 0 .09.993h2l.09-.009A.5.5 0 0 0 8 12Zm-.5-4.5a.5.5 0 0 1 .09.992L7.5 8.5h-2a.5.5 0 0 1-.09-.991L5.5 7.5h2Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 .5a.5.5 0 0 1 .587-.492l.081.022 7 2.5a.5.5 0 0 1 .324.382L16 3v12a.5.5 0 0 1-.41.492l-.09.008H.5a.5.5 0 0 1-.492-.41L0 15V8a.5.5 0 0 1 .41-.491L.5 7.5h1a.5.5 0 0 1 .09.992L1.5 8.5H1v6h2V5a.5.5 0 0 1 .41-.492L3.5 4.5h6a.5.5 0 0 1 .492.41L10 5v9.5h5V3.352L9 1.21V3a.5.5 0 0 1-.41.492L8.5 3.5a.5.5 0 0 1-.492-.41L8 3V.5Zm1 5H4v9h5v-9Z"
      ></path>
      <path d="M11.5 3.5a.5.5 0 0 1 .492.41L12 4v8a.5.5 0 0 1-.992.09L11 12V4a.5.5 0 0 1 .5-.5Zm2.492 1.41A.5.5 0 0 0 13 5v7l.008.09A.5.5 0 0 0 14 12V5l-.008-.09Z"></path>
    </Icon>
  )
}

export default BuildingIcon

import axios from 'axios'

export const searchProfessions = (body) =>
  axios
    .post('/mobiville/api/professions/search', body)
    .then((response) => response.data || [])

export const searchInfosTravail = (body) =>
  axios
    .post('/mobiville/api/professions/infos-travail', body)
    .then((response) => response.data || [])

export const searchProfessionsCountList = (body) =>
  axios
    .post('/mobiville/api/professions/searchCountList', body)
    .then((response) => response.data || [])

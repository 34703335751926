import Icon from './Icon'

const SearchIcon = ({ size, ...props }) => (
  <Icon iconName="icon-search" size={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 0a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13Zm0 1a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Z"
    ></path>
    <path d="M12.784 12.089a.5.5 0 0 0-.638.765l3 3 .07.057a.5.5 0 0 0 .638-.765l-3-3-.07-.057Z"></path>
  </Icon>
)

export default SearchIcon

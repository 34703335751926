import Icon from './Icon'

const UsersIcon = ({ size = 16, ...props }) => {
  if (+size > 16) {
    return (
      <Icon iconName="icon-users-lg" size={size} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 14c0 4.98-4.01 11-9 11s-9-6.04-9-11 4.04-9 9-9 9 4.02 9 9Zm-2 0c0-3.86-3.14-7-7-7s-7 3.03-7 7 3.3 9 7 9 7-5.14 7-9Zm14 11c4.43 0 8-5.47 8-10s-3.59-8-8-8-8 3.59-8 8 3.57 10 8 10Zm0-16c3.31 0 6 2.69 6 6 0 3.49-2.91 8-6 8s-6-4.51-6-8c0-3.31 2.69-6 6-6Zm8.26 20.24C42.52 28.68 39.68 28 36 28c-3.68 0-6.52.67-8.26 1.24-.59.19-1.11.53-1.55.96-.17-.09-.35-.17-.53-.24C23.15 29.07 19.26 28 15 28s-8.15 1.07-10.66 1.96c-2 .71-3.34 2.61-3.34 4.72V42c0 .55.45 1 1 1h26c.55 0 1-.45 1-1v-7.32c0-1.21-.46-2.35-1.22-3.24.17-.13.36-.23.57-.3C29.95 30.62 32.58 30 36 30s6.04.62 7.65 1.14c.81.26 1.35 1.02 1.35 1.89V37H34c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1v-4.97c0-1.74-1.1-3.27-2.74-3.8v.01ZM3 41v-6.32c0-1.27.81-2.41 2.01-2.83 2.36-.84 6.02-1.84 9.99-1.84 3.97 0 7.62 1 9.99 1.84 1.2.43 2.01 1.57 2.01 2.83V41H3Z"
        ></path>
      </Icon>
    )
  }
  return (
    <Icon iconName="icon-users" size={size} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 1a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm0 1a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm7 0a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0 1a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm-7 6 .238.005A5.5 5.5 0 0 1 11 14.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5A5.5 5.5 0 0 1 5.5 9Zm.206 1.005L5.489 10l-.208.005a4.501 4.501 0 0 0-4.238 3.867L1.027 14H9.97l-.017-.146a4.5 4.5 0 0 0-4.031-3.835l-.216-.014Z"
      ></path>
      <path d="M15.995 11.308a3.5 3.5 0 0 0-5.431-2.724.5.5 0 1 0 .554.832c.409-.272.89-.417 1.381-.416l.165.005A2.5 2.5 0 0 1 15 11.5l-.001.499-2.5.001-.089.008a.5.5 0 0 0 .09.992h3l.09-.008A.5.5 0 0 0 16 12.5v-1l-.005-.192Z"></path>
    </Icon>
  )
}

export default UsersIcon
